.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contentContainer > h1 {
    text-align: center;
    color: rgb(255, 145, 0);
}


.contentContainer > p {
    margin: 75px;
}
