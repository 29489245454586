.backContainer {
  margin-top: 50px;
  text-align: center;
}

.backContainer > a > img {
  border-radius: 50%;
  border: 5px solid rgb(255, 255, 255);
  background-color: whitesmoke;
  width: 75px;
}

.backContainer > a > img:hover {
  background-color: rgb(255, 223, 181);
  box-shadow: 0 0 15px rgb(255, 223, 181);
}
