.main {
  display: flex;
  flex-direction: column;
}

.navContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px 10px;
}

.homeLink {
  text-decoration: none;
  color: rgb(255, 145, 0);
}

.transcript {
  padding: 20px 0px 60px 0px;
  color: rgb(255, 223, 181);
  align-self: center;
}

.loginButton {
  color: rgb(255, 223, 181);
  cursor: pointer;
  padding: 10px 20px;
  background-color: transparent;
  border-radius: 10px;
  border: 3px solid rgb(255, 223, 181);
  font-size: 20px;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  color: rgb(255, 145, 0);
  font-size: 75px;
}

.login {
  padding: 0px 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.greetUser {
  display: none;
  margin: 20px;
  color: rgb(255, 223, 181);
}

.titleImage {
  margin-left: 20px;
  width: 100px;
  border-radius: 50%;
}

.navContainer > button {
  justify-items: flex-end;
}

@media screen and (max-width: 1100px) {
  .navContainer {
    flex-direction: column;
    align-items: center;
  }

  .transcript {
    text-align: center;
  }

  .login {
    width: auto;
    margin-top: 20px;
    flex-wrap: wrap;
  }
}
