.episodeContainer {
  display: flex;
  justify-content: center;
}

.episodeMain {
  display: flex;
  align-items: center;
  background-color: whitesmoke;
  margin: 10px;
  border-radius: 10px;
  min-width: 50%;
  height: 75px;
}
.episodeMain:hover {
  box-shadow: 0 0 15px rgb(255, 145, 0);
}

.youtubeVideo {
  text-decoration: none;
}

.videoIcon {
  margin: 5px 20px 0px 20px;
  width: 50px;
}

.episodeBtn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 700px;
  height: 75px;
  cursor: pointer;
  border: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgb(255, 223, 181);
  font-size: 20px;
}

.episodeBtn > span {
  margin: 0px 15px;
  font-weight: bold;
  color: rgb(255, 145, 0);
}

@media screen and (min-width: 1550px) {
  .episodeBtn {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .episodeBtn > span {
    font-size: 15px;
  }

  .episodeBtn > p {
    font-size: 15px;
  }
}