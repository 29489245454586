.notFound {
  display: flex;
  justify-content: center;
  text-align: center;
}

.notFound > h1 > a {
  text-decoration: none;
  color: rgb(255, 145, 0);
}
