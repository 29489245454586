.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20vh;
  border-top: 1px solid rgb(255, 223, 181);
}

.innerContainer > p {
  font-size: 20px;
}
